import styled from "styled-components";
import { Link } from "react-router-dom";

import SocialTray from "../components/SocialTray";
import breakpoints from "../styles/breakpoints";

import TLLogo from "../assets/products/tl-logo.svg";
import TRLogo from "../assets/products/tr-logo.svg";
import TL_BL from "../assets/products/desktop/tl-bl.svg";
import TL_TR from "../assets/products/desktop/tl-tr.svg";
import TR_rect from "../assets/products/desktop/tr-rect.svg";
import TL_BL_s from "../assets/products/mobile/tl-bl.svg";
import TL_TR_s from "../assets/products/mobile/tl-tr.svg";
import TR_rect_s from "../assets/products/mobile/tr-rect.svg";

const ProductsContainer = styled.div`
  display: grid;
  height: calc(100vh - 50px - 40px);
  grid-template-columns: repeat(2, 1fr);

  @media ${breakpoints.mobile} {
    height: calc(100vh - 56px - 56px);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`;

const ProductContainer = styled.div`
  background-color: ${(props) => props?.backgroundcolor || "white"};
  display: grid;
  ${(props) =>
    props.type === "tl" ? "align-items: center;" : "place-content: center;"}
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.type === "tl" ? `url(${TL_BL}), url(${TL_TR})` : `url(${TR_rect})`};
  background-position: ${(props) =>
    props.type === "tl" ? "bottom left, top right" : `center`};
  background-size: ${(props) => (props.type !== "tl" ? "cover" : "")};

  @media ${breakpoints.mobile} {
    place-content: center;
    background-image: ${(props) =>
      props.type === "tl"
        ? `url(${TL_BL_s}), url(${TL_TR_s})`
        : `url(${TR_rect_s})`};
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => (props.type === "tl" ? "padding-left: 15vw;" : "")}

  @media ${breakpoints.tablet} {
    width: 90%;
    margin: 0 auto;
    ${(props) => (props.type === "tl" ? "padding-left: 5vw;" : "")}
  }

  @media ${breakpoints.mobile} {
    align-items: center;
    padding: 0;
  }
`;

const IconDiv = styled.div`
  margin-bottom: 1em;

  @media ${breakpoints.tablet} {
    width: 90%;
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;

const Text = styled.p`
  width: 350px;
  line-height: 25.6px;
  font-size: 1rem;
  margin-bottom: 1.5em;

  @media ${breakpoints.tablet} {
    width: auto;
  }

  @media ${breakpoints.mobile} {
    font-size: 0.875rem;
    text-align: center;
  }
`;

const Button = styled(Link)`
  background-color: ${(props) => props.backgroundcolor || "none"};
  border: none;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
  padding: 0.75em 1em;
  margin-bottom: 2em;
  text-decoration: none;
  box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05),
    0px 6px 6px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
`;

const Products = () => {
  return (
    <ProductsContainer>
      <ProductContainer backgroundcolor="#d6ffed" type="tl">
        <MainContent type="tl">
          <IconDiv>
            <img src={TLLogo} alt="Trading Leagues" />
          </IconDiv>
          <Text>
            A new, fun and effortless experience of the stock markets. Say
            goodbye to a brokerage account, capital, and stress.
          </Text>
          <Button backgroundcolor="#24DC8E" to="/product/trading-leagues">
            Learn more
          </Button>
          <SocialTray fill="#17B471" type="tl" />
        </MainContent>
      </ProductContainer>
      <ProductContainer backgroundcolor="#d7f2ff">
        <MainContent>
          <IconDiv>
            <img src={TRLogo} alt="Trading Rooms" />
          </IconDiv>
          <Text>
            The future of trading is finally here. TradingRooms is India’s first
            automated social investing and trading platform.
          </Text>
          <Button backgroundcolor="#53BEED" to="/product/trading-rooms">
            Learn more
          </Button>
          <SocialTray fill="#53BEED" type="tr" />
        </MainContent>
      </ProductContainer>
    </ProductsContainer>
  );
};

export default Products;
