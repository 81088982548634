import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import SocialTray from "../components/SocialTray";
import breakpoints from "../styles/breakpoints";

import TLLogo from "../assets/products/tl-logo.svg";
import BackIcon from "../assets/back.svg";
import Mockup from "../assets/mockups/tl.png";
import TL_BL from "../assets/products/desktop/tl-bl.svg";
import TL_TR from "../assets/products/desktop/tl-tr.svg";
import TL_BL_s from "../assets/products/mobile/tl-bl.svg";
import TL_TR_s from "../assets/products/mobile/tl-tr.svg";

const ProductContainer = styled.div`
  display: grid;
  height: calc(100vh - 50px - 40px);
  grid-template-columns: repeat(2, 1fr);

  @media ${breakpoints.mobile} {
    height: calc(100vh - 56px - 56px);
    grid-template-columns: 1fr;
    grid-template-rows: 9fr 13fr;
  }
`;

const ProductSummary = styled.div`
  background-color: ${(props) => props?.backgroundcolor || "white"};
  background-repeat: no-repeat;
  background-image: url(${TL_BL}), url(${TL_TR});
  background-position: bottom left, top right;

  @media ${breakpoints.mobile} {
    padding: 1em;
    place-content: center;
    background-image: url(${TL_BL_s}), url(${TL_TR_s});
  }
`;

const ProductDetail = styled.div`
  background-color: ${(props) => props?.backgroundcolor || "white"};
  height: 100%;
  position: relative;

  @media ${breakpoints.mobile} {
    padding: 1em;
  }
`;

const Back = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-decoration: none;
  color: currentColor;
  margin: 1.5em 0 0 15vw;
  cursor: pointer;

  & span {
    font-size: 1rem;
    font-weight: 600;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
    margin-bottom: 2em;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15vw;
  margin-top: 20vh;

  @media ${breakpoints.tablet} {
    width: 90%;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
  }
`;

const IconDiv = styled.div`
  margin-bottom: 1em;

  @media ${breakpoints.tablet} {
    width: 90%;
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;

const SummaryText = styled.p`
  width: 350px;
  line-height: 25.6px;
  font-size: 1rem;
  margin-bottom: 1.5em;

  @media ${breakpoints.tablet} {
    width: 90%;
  }

  @media ${breakpoints.mobile} {
    width: auto;
  }
`;

const Button = styled("a")`
  background-color: ${(props) => props.backgroundcolor || "none"};
  border: none;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
  padding: 0.75em 1em;
  margin-bottom: 2em;
  text-decoration: none;
  box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05),
    0px 6px 6px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
`;

const DetailsText = styled.p`
  line-height: 25.6px;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 3vw;
  width: min(460px, 90%);
  margin-top: 15vh;
  text-align: justify;

  @media ${breakpoints.tablet} {
    font-size: 0.875rem;
    width: 90%;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
    font-size: 0.875rem;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  @media ${breakpoints.mobile} {
    align-items: flex-start;
  }
`;

const DetailsBottom = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 2em;
  margin-left: 3vw;
  margin-top: auto;

  @media ${breakpoints.tablet} {
    position: relative;
  }

  @media ${breakpoints.mobile} {
    margin-left: 0;
  }
`;

const MockupContainer = styled.div`
  height: 275px;
  box-shadow: 11.6223px 11.6223px 29.0557px rgba(0, 0, 0, 0.35),
    46.4891px 46.4891px 87.1671px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #eaf9f2 0%, #dceae3 100%);
  border-radius: 20px 20px 0 0;
  overflow: hidden;

  & img {
    display: block;
    height: 100%;
    width: auto;
  }

  @media (max-width: 1440px) {
    height: 200px;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }

  @media ${breakpoints.mobile} {
    font-size: 0.875rem;
    text-align: center;
  }
`;

const TradingLeagues = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <ProductContainer>
      <ProductSummary backgroundcolor="#D6FFED">
        <Back onClick={goBack}>
          <img src={BackIcon} alt="back" />
          <span>Back</span>
        </Back>
        <MainContent>
          <IconDiv>
            <img src={TLLogo} alt="Trading Leagues" />
          </IconDiv>
          <SummaryText>
            A new, fun and effortless experience of the stock markets. Say
            goodbye to a brokerage account, capital, and stress.
          </SummaryText>
        </MainContent>
      </ProductSummary>
      <ProductDetail backgroundcolor="#F0FFF8">
        <DetailsText>
          Being an offspring of DFS (Daily Fantasy Sports) and the stock
          markets, TradingLeagues removes the entry barriers associated with
          opening a brokerage account and funding a brokerage account.
          TradingLeagues is aimed to introduce those who don’t have a brokerage
          account, to the stock markets in a gamified and light-touch manner.
        </DetailsText>
        <DetailsBottom>
          <LinksContainer>
            <Button
              backgroundcolor="#24DC8E"
              href="https://www.tradingleagues.in"
              target="_blank"
              rel="noreferrer"
            >
              Explore TradingLeagues
            </Button>
            <SocialTray fill="#17B471" jc="flex-start" />
          </LinksContainer>
          <MockupContainer>
            <img src={Mockup} alt="mockup" />
          </MockupContainer>
        </DetailsBottom>
      </ProductDetail>
    </ProductContainer>
  );
};

export default TradingLeagues;
