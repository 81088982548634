/** @format */

import styled from "styled-components";
import { Link } from "react-router-dom";
import BackgroundLg from "../assets/background/homepageLg.svg";
import BackgroundMobile from "../assets/background/homepage_mobile.svg";
import breakpoints from "../styles/breakpoints";
import TRRoomLogo_full from "../assets/pr-icon/TrRoomsLogo_full.svg";
import TLlogo_full from "../assets/pr-icon/TLlogo_full.svg";

const HomeStyledDiv = styled.div`
	height: calc(100vh - 88px - 40px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 15vw;
	background: url(${BackgroundLg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media ${breakpoints.mobile} {
		height: calc(100vh - 56px - 56px);
		padding: 0 1rem;
		background: url(${BackgroundMobile});
	}
`;
const TitleContainer = styled.div`
	font-size: 3rem;
	margin-bottom: 1.5rem;
`;

const ReimaginedSpan = styled.span`
	display: inline-block;
	background: linear-gradient(190.9deg, #00e5ff, #4676e0);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 700;
`;

const DescriptionDiv = styled.p`
	width: min(600px, 90%);
	line-height: 1.5;
	margin-bottom: 1.5rem;

	@media ${breakpoints.mobile} {
		width: 100%;
		margin-bottom: 3rem;
	}
`;

const ProductIconContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1em;
`;

const ProductIconWrapper = styled(Link)`
	display: grid;
	place-items: center;
	margin-right: 1rem;
	padding: 1.5px;
	background: ${(props) =>
		`linear-gradient(135deg, ${
			props.type === "tl" ? "#2236aa" : "#31429B"
		} 0%, ${props.type === "tl" ? "#24dc8e" : "#53BEED"} 100%)`};
	border: none;
	border-radius: 10px;
	box-shadow: ${(props) => `-3px -3px 5px 0.5px rgba(${
		props.type === "tl" ? "34, 54, 170" : "49, 66, 155"
	}, 0.25),
    3px 3px 5px 0.5px rgba(${
			props.type === "tl" ? "36, 220, 142" : "83, 190, 237"
		}, 0.25)`};

	@media ${breakpoints.mobile} {
		padding: 1px;
		border-radius: 5px;
	}
`;

const ProductIcon = styled.div`
	padding: 0.5rem 0.75em;
	border-radius: 8px;
	background: white;
	cursor: pointer;
	max-width: calc(26px + 1rem);
	display: grid;
	place-items: center;
	overflow: hidden;
	transition: max-width 175ms ease-out;

	& img {
		display: block;
		height: 26px;
		width: auto;
		position: relative;
		left: ${(props) => (props.type === "tl" ? "2px" : "0")};
		backface-visibility: none;
		transition: left 175ms;
	}

	&:hover {
		max-width: 312px;

		& img {
			left: 0;
		}
	}

	@media ${breakpoints.mobile} {
		padding: 0.5rem;
		border-radius: 5px;
		max-width: calc(25px + 1rem);

		& img {
			display: block;
			height: 25px;
			left: ${(props) => (props.type === "tl" ? "6px" : "3px")};
			pointer-events: none;
		}

		&.clicked {
			max-width: 312px;

			& img {
				left: 0;
			}
		}
	}
`;

const Home = () => {
	const onBtnClick = (ev) => {
		ev.target.classList.add("clicked");
		setTimeout(() => ev.target.classList.remove("clicked"), 700);
	};

	return (
		<HomeStyledDiv>
			{/* title */}
			<TitleContainer>
				The financial markets,
				<br /> <ReimaginedSpan>reimagined</ReimaginedSpan>
			</TitleContainer>

			<DescriptionDiv>
				TLX Labs builds technologies that help remove barriers between
				people and the financial world. Our mission is to aggressively increase
				investor participation rates globally and fundamentally alter
				assumptions associated with trading and investing.
			</DescriptionDiv>
{ /*
			<ProductIconContainer>
				<ProductIconWrapper
					type="tl"
					to="/product/trading-leagues"
					onClick={onBtnClick}
				>
					<ProductIcon type="tl">
						<img src={TLlogo_full} alt="tl_logo_symbol" />
					</ProductIcon>
				</ProductIconWrapper>
				<ProductIconWrapper
					type="tr"
					to="/product/trading-rooms"
					onClick={onBtnClick}
				>
					<ProductIcon type="tr">
						<img src={TRRoomLogo_full} alt="tr_logo_symbol" />
					</ProductIcon>
				</ProductIconWrapper>
			</ProductIconContainer> 
*/ }
		</HomeStyledDiv>
	);
};

export default Home;
