import styled from "styled-components";

import SocialLinks from "../data/social-links";
import breakpoints from "../styles/breakpoints";

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row-reverse;
  background-color: #f7f7f7;
  // background-color: red;
  padding: 0.75em 15vw;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  position: relative;
  z-index: 5;

  @media ${breakpoints.tablet} {
    padding: 0.75em 5vw;
  }

  @media ${breakpoints.mobile} {
    display: block;
    padding: 0.75em;
  }
`;

const TextDiv = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;

  @media ${breakpoints.mobile} {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 0.5rem;
  }
`;

const IconsDiv = styled.div`
  display: inline-block;

  @media ${breakpoints.mobile} {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
`;

const IconsConainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;

  @media ${breakpoints.tablet} {
    gap: 1em;
  }

  @media ${breakpoints.mobile} {
    gap: 2em;
  }
`;

const SocialLink = styled.a`
  display: inline-block;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <IconsDiv>
        <IconsConainer>
          {SocialLinks.map((x) => (
            <SocialLink
              key={x.title}
              title={x.title}
              href={x.link}
              target="_blank"
              rel="noopener"
            >
              <img src={x.icon} alt={x.title} />
            </SocialLink>
          ))}
        </IconsConainer>
      </IconsDiv>
      <TextDiv>
        <p>&#169; 2022, TLX Labs,  All rights reserved.</p>
      </TextDiv>
    </StyledFooter>
  );
};

export default Footer;
