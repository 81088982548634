import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import PageLinks from "../data/page-links";

import Logo from "../assets/logo.svg";
import MenuIcon from "../assets/menu.svg";

const Nav = styled.nav`
  display: flex;
  background-color: #f7f7f7;
  padding: 0.2em 15vw;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media ${breakpoints.tablet} {
    padding: 0.75em 5vw;
  }

  @media ${breakpoints.mobile} {
    padding: 0.75em;
  }
`;

const LogoContainer = styled.div`
  display: inline-block;
`;

const LogoLink = styled(Link)``;

const LinksContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 3em;

  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: currentColor;
  font-weight: ${(props) => (props.isactive === "true" ? "700" : "400")};
  font-size: 0.875rem;
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const MobileMenu = styled.ul`
  display: none;
  list-style: none;
  font-size: 0.875rem;
  font-weight: 400;
  position: absolute;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-out, padding 200ms ease-out,
    opacity 400ms ease-out;
  background-color: #f7f7f7;
  padding: 0;
  box-shadow: 0px 72px 29px rgba(0, 0, 0, 0.01),
    0px 40px 24px rgba(0, 0, 0, 0.05), 0px 18px 18px rgba(0, 0, 0, 0.09),
    0px 4px 10px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  top: calc(100% + 3px);
  right: 5px;
  opacity: 0;

  & li {
    text-align: center;
  }

  & li:not(:last-child) {
    margin-bottom: 1.5em;
  }

  & a {
    text-decoration: none;
    color: currentColor;
  }

  &.opened {
    max-height: 120px;
    padding: 1em;
    opacity: 1;
  }

  @media ${breakpoints.mobile} {
    display: block;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [openMenu, setOpenMenu] = useState(false);
  const mobileMenu = useRef();

  useEffect(() => {
    const el = mobileMenu.current;
    if (el) {
      el.classList[openMenu ? "add" : "remove"]("opened");
    }
  }, [openMenu]);

  return (
    <Nav>
      <LogoContainer>
        <LogoLink to="/home">
          <img
            src={Logo}
            alt="rain"
            style={{ height: "80px", width: "140px" }}
          />
        </LogoLink>
      </LogoContainer>
      <LinksContainer>
        {PageLinks.map((x) => (
          <StyledLink
            key={x.title}
            to={x.link}
            isactive={(pathname === x.link).toString()}
          >
            {x.title}
          </StyledLink>
        ))}
      </LinksContainer>
      <MenuToggle onClick={() => setOpenMenu(!openMenu)}>
        <img src={MenuIcon} alt="menu" />
      </MenuToggle>
      <MobileMenu ref={mobileMenu}>
        {PageLinks.map((x) => (
          <li key={x.link}>
            <Link to={x.link} onClick={() => setOpenMenu(false)}>
              {x.title}
            </Link>
          </li>
        ))}
      </MobileMenu>
    </Nav>
  );
};

export default Navbar;
