import styled from "styled-components";
import device from "../styles/breakpoints";

import BG from "../assets/contact/bg.svg";
import BG_s from "../assets/contact/bg_s.svg";

const ContactWrapper = styled.main`
  height: calc(100vh - 88px - 40px);
  overflow-y: auto;
  display: grid;
  place-items: center;
  // background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: 100% 0;

  @media ${device.mobile} {
    height: calc(100vh - 56px - 56px);
    display: block;
    padding-left: 2em;
    background-repeat: no-repeat;
    // background-image: url(${BG_s});
    background-position: 100% 50%;
  }
`;

const MainContent = styled.div`
  width: 70%;

  @media ${device.mobile} {
    margin-top: 2em;
  }
`;

const Header = styled.header`
  margin-bottom: 3em;

  & h1 {
    font-size: 2.5rem;
    font-weight: 600;
  }

  & p {
    font-size: 0.875rem;
    font-weight: 500;
    width: 375px;
    line-height: 19.6px;
  }

  @media ${device.mobile} {
    & h1 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0.5em;
    }

    & p {
      width: 95%;
    }
  }
`;

const ContactDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.mobile} {
    display: block;
  }
`;

const ContactItem = styled.div`
  & + & {
    margin-top: 2em; /* Add some margin between items */
  }

  & img {
    margin-bottom: 0.25em;
    display: none;
  }

  & h3 {
    margin-bottom: 0.2em;
    font-size: 1rem;
    font-weight: 600;

    & a {
      text-decoration: none;
      color: currentColor;
    }
  }

  & h4 {
    margin-bottom: 0.2em;
    font-size: 0.75rem;
    font-weight: 400;

    & a {
      text-decoration: none;
      color: currentColor;
    }
  }

  & p {
    max-width: 300px;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  @media ${device.mobile} {
    margin-bottom: 2em;
  }
`;

const Contact = () => {
  return (
    <ContactWrapper>
      <MainContent>
        <Header>
          <h1>Contact us</h1>
          <p>
            Drop us a line and we will get back to you. Don’t hesitate to say
            hi, we would love to get to know you.
          </p>
        </Header>
        <ContactDetails>
          <ContactItem grid-area="a">
            <h3>India Office</h3>
            <p>
              Level 15, Concorde Towers, UB City, Vittal Mallaya Road,
              Bengaluru, Karnataka, India - 560001
            </p>
            <h4>
              <a href="tel:9999999999">+91 9115997999</a> |{" "}
              <a href="mailto:contact@rainplatforms.com">
                contact@rainplatforms.com
              </a>{" "}
              |{" "}
              <a href="mailto:support@tradingleagues.app">
                support@tradingleagues.app
              </a>
            </h4>
          </ContactItem>
          <ContactItem grid-area="c">
            <h3>USA Office</h3>
            <p>
              1013 Centre Road, Suite 403-B, Wilmington, New Castle, Delaware
              19805-1270, USA
            </p>
          </ContactItem>
          <ContactItem grid-area="b">
            <h3>Singapore Office</h3>
            <p>
              M Hotel Singapore, 81 Anson Road, Suites 08.23 & 08.27, Singapore
              079908
            </p>
          </ContactItem>
        </ContactDetails>
      </MainContent>
    </ContactWrapper>
  );
};

export default Contact;
