/** @format */

import styled from "styled-components";
import { coreTeam, foundingTeam } from "../data/teams";
import device from "../styles/breakpoints";

import BackgroundLg from "../assets/background/homepageLg.svg";
import BackgroundMobile from "../assets/background/homepage_mobile.svg";
import { ReactComponent as LinkedInIcon } from "../assets/social/linkedin.svg";

const TeamWrapper = styled.div`
  height: calc(100vh - 80px - 40px);
  overflow-y: auto;
  background: url(${BackgroundLg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.mobile} {
    height: calc(100vh - 56px - 56px);
    background: url(${BackgroundMobile});
  }
`;

const TeamsStyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
`;

const TtileDiv = styled.h1`
  font-weight: 600;
  font-size: 2.5rem;
  margin-top: 1rem;
`;

const TtileDiv2 = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1rem;
`;

const DescriptionDiv = styled.div`
  width: 40%;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2.5em;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const TeamDiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.type === "founding" ? "repeat(3, 1fr)" : "repeat(3, 1fr)"};
  column-gap: ${(props) => (props.type === "founding" ? "5em" : "5em")};
  margin-top: 2rem;
  margin-bottom: ${(props) => (props.type === "founding" ? "2em" : "2em")};

  @media ${device.mobile} {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2em;
    justify-items: center;
  }
`;

const MemberDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.type === "founding" ? "2rem" : "2rem")};
  grid-column: auto;
  width: 18rem;
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  & img {
    height: 100px;
    width: 100px;
  }

  & h3 {
    font-size: 1rem;
    font-weight: 700;
  }

  & span {
    font-size: 0.75rem;
    font-weight: 500;
  }
  margin-bottom: 3rem;

  @media ${device.mobile} {
    grid-column: auto;
    width:auto;
    & img {
      height: 80px;
      width: 80px;
    }
    margin-bottom: 2.5rem;

    & span {
      font-size: 0.7rem;
    }
  }
`;

const StyledSpan = styled.span`
  text-align: center;
  `;

const renderTeam = (team, type) => {
  return team?.map((mem, i) => (
    <MemberDiv type={type} key={mem.name} index={i}>
      <img src={mem.img} alt={`${mem.name}-img`}></img>
      <h3>{mem.name}</h3>
      <StyledSpan>{mem.title}</StyledSpan>
      <a href={mem.linkedIn} target="_blank" rel="noreferrer">
        <LinkedInIcon />
      </a>
    </MemberDiv>
  ));
};

const Team = () => {
  return (
    <TeamWrapper>
      <TeamsStyledDiv>
        {/* -- title --- */}
        <TtileDiv>Meet the Team</TtileDiv>
        {/* -- description -- */}
        <DescriptionDiv>
          We are an eccentric group of individuals, who genuinely believe that
          we can revolutionise the financial world.
        </DescriptionDiv>
        <br />
        {/* --- founding team --- */}
        <TtileDiv2>Founding Team</TtileDiv2>
        <TeamDiv type="founding">
          {renderTeam(foundingTeam, "founding")}
        </TeamDiv>
        <br />
        {/* --- core team --- */}
        <TtileDiv2>Core Team</TtileDiv2>
        <TeamDiv>{renderTeam(coreTeam, "core")}</TeamDiv>
      </TeamsStyledDiv>
    </TeamWrapper>
  );
};

export default Team;
