const PageLinks = [
 
  {
    title: "Team",
    link: "/team",
  },
  {
    title: "Contact",
    link: "/contact",
  },
];

 // {
  //   title: "Products",
  //   link: "/products",
  // },
export default PageLinks;
