// import TwitterIcon from "../assets/social/twitter.svg";
// import InstaIcon from "../assets/social/insta.svg";
import LinkedInIcon from "../assets/social/linkedin.svg";

const SocialLinks = [
  /*
  {
    title: "Twitter",
    link: "https://twitter.com/rainplatforms",
    icon: TwitterIcon,
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/rainplatforms",
    icon: InstaIcon,
  },

  */
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/rainplatformshq/",
    icon: LinkedInIcon,
  },
];

export default SocialLinks;
