import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import { TLLinks, TRLinks } from "../data/product-links";

const Tray = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) => props.jc || "center"};
  gap: 1em;

  & svg path {
    fill: ${(props) => props.fill || "#01010b"};
  }

  @media ${breakpoints.mobile} {
    gap: 2em;
  }
`;

const SocialTray = ({ fill, jc, type }) => {
  return (
    <Tray fill={fill} jc={jc}>
      {(type === "tr" ? TRLinks : TLLinks).map((x) => (
        <a
          key={x.title}
          title={x.title}
          href={x.link}
          target="_blank"
          rel="noreferrer"
        >
          <x.Icon />
        </a>
      ))}
    </Tray>
  );
};

export default SocialTray;
