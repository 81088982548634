import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SocialTray from "../components/SocialTray";
import breakpoints from "../styles/breakpoints";

import TRLogo from "../assets/products/tr-logo.svg";
import BackIcon from "../assets/back.svg";
import Mockup from "../assets/mockups/tr.png";
import TR_rect from "../assets/products/desktop/tr-rect.svg";
import TR_rect_s from "../assets/products/mobile/tr-rect.svg";

const ProductContainer = styled.div`
  display: grid;
  height: calc(100vh - 50px - 40px);
  grid-template-columns: repeat(2, 1fr);

  @media ${breakpoints.mobile} {
    height: calc(100vh - 56px - 56px);
    grid-template-columns: 1fr;
    grid-template-rows: 9fr 13fr;
  }
`;

const ProductSummary = styled.div`
  background-color: ${(props) => props?.backgroundcolor || "white"};
  height: 100%;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  position: relative;
  background-repeat: no-repeat;
  background-image: url(${TR_rect});
  background-position: 100% 100%;
  background-size: cover;

  @media ${breakpoints.mobile} {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 1em;
    background-image: url(${TR_rect_s});
  }
`;

const ProductDetail = styled.div`
  background-color: ${(props) => props?.backgroundcolor || "white"};
  height: 100%;
  position: relative;
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  @media ${breakpoints.mobile} {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 1em;
  }
`;

const Back = styled.button`
  background: none;
  border: none;
  display: ${(props) => (props.view === "mobile" ? "none" : "flex")};
  align-items: center;
  gap: 0.5em;
  text-decoration: none;
  color: currentColor;
  margin: 1.5em 0 0 15vw;
  cursor: pointer;

  & span {
    font-size: 1rem;
    font-weight: 600;
  }

  @media ${breakpoints.tablet} {
    margin-left: 5vw;
  }

  @media ${breakpoints.mobile} {
    display: ${(props) => (props.view === "mobile" ? "flex" : "none")};
    margin: 0;
    margin-bottom: 2em;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7.5vw;
  transform: translateY(50%);
  position: absolute;
  bottom: 50%;

  @media ${breakpoints.tablet} {
    width: 90%;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
    position: relative;
    bottom: auto;
    transform: translateY(0);
  }
`;

const IconDiv = styled.div`
  margin-bottom: 1em;

  @media ${breakpoints.tablet} {
    width: 90%;
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;

const SummaryText = styled.p`
  width: 350px;
  line-height: 25.6px;
  font-size: 1rem;
  margin-bottom: 1.5em;

  @media ${breakpoints.tablet} {
    width: 90%;
  }

  @media ${breakpoints.mobile} {
    width: auto;
  }
`;

const Button = styled.a`
  background-color: ${(props) => props.backgroundcolor || "none"};
  border: none;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
  padding: 0.75em 1em;
  margin-bottom: 2em;
  text-decoration: none;
  box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05),
    0px 6px 6px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
`;

const DetailsText = styled.p`
  line-height: 25.6px;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 15vw;
  margin-right: 3vw;
  margin-top: 15vh;
  text-align: justify;

  @media ${breakpoints.tablet} {
    font-size: 0.875rem;
    width: 80%;
    margin-left: 5vw;
  }

  @media ${breakpoints.mobile} {
    margin: 0;
    font-size: 0.875rem;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  @media ${breakpoints.mobile} {
    align-items: flex-start;
  }
`;

const DetailsBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 15vw;
  display: flex;
  gap: 2em;
  margin-top: auto;

  @media ${breakpoints.tablet} {
    position: relative;
  }

  @media ${breakpoints.mobile} {
    position: relative;
    right: auto;
    left: auto;
  }
`;

const MockupContainer = styled.div`
  height: 275px;
  box-shadow: 11.6223px 11.6223px 29.0557px rgba(0, 0, 0, 0.35),
    46.4891px 46.4891px 87.1671px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #eaf9f2 0%, #d8e6e0 100%);
  border-radius: 20px 20px 0 0;
  overflow: hidden;

  & img {
    display: block;
    height: 100%;
    width: auto;
  }

  @media (max-width: 1440px) {
    height: 200px;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }

  @media ${breakpoints.mobile} {
    font-size: 0.875rem;
    text-align: center;
  }
`;

const TradingRooms = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <ProductContainer>
      <ProductSummary backgroundcolor="#D7F2FF">
        <Back view="mobile" onClick={goBack}>
          <img src={BackIcon} alt="back" />
          <span>Back</span>
        </Back>
        <MainContent>
          <IconDiv>
            <img src={TRLogo} alt="Trading Leagues" />
          </IconDiv>
          <SummaryText>
            The future of trading is finally here. TradingRooms is India’s first
            automated social investing and trading platform.
          </SummaryText>
        </MainContent>
      </ProductSummary>
      <ProductDetail backgroundcolor="#F8FDFF">
        <Back onClick={goBack}>
          <img src={BackIcon} alt="back" />
          <span>Back</span>
        </Back>
        <DetailsText>
          We are India’s first automated social investing and trading platform.
          TradingRooms enables the newbies in the financial marketplace to copy
          the actions of professional traders and investors. We are on a mission
          to help bring simplicity and convenience to the world of trading.
        </DetailsText>
        <DetailsBottom>
          <LinksContainer>
            <Button
              backgroundcolor="#53BEED"
              href="https://www.tradingrooms.in"
              target="_blank"
              rel="noreferrer"
            >
              Explore TradingRooms
            </Button>
            <SocialTray fill="#53BEED" jc="flex-start" type="tr" />
          </LinksContainer>
          <MockupContainer>
            <img src={Mockup} alt="mockup" />
          </MockupContainer>
        </DetailsBottom>
      </ProductDetail>
    </ProductContainer>
  );
};

export default TradingRooms;
