/** @format */

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import Home from "./views/Home";
import Contact from "./views/Contact";
import Team from "./views/Team";
import Products from "./views/Products";
import TradingLeagues from "./views/TradingLeagues";
import TradingRooms from "./views/TradingRooms";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./App.css";

function App() {
  useEffect(() => {
    if (window.location.hostname === "www.rainplatforms.com") {
      window.location.replace("https://www.tlxhq.com/");
    }
  }, []);
  let location = useLocation();

  return (
    <div className="App">
      <Navbar />
      <TransitionGroup>
        <CSSTransition key={location.pathname} timeout={200} classNames="page">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/team" element={<Team />} />
            {/*
             <Route path="/products" element={<Products />} />
             <Route
              path="/product/trading-leagues"
              element={<TradingLeagues />}
            />
            <Route path="/product/trading-rooms" element={<TradingRooms />} /> 
*/}
            <Route path="/home" element={<Navigate to="/" replace />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

export default App;
