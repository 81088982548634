/** @format */

import Raghu from "../assets/Team/Raghu.png";
import Harsh from "../assets/Team/Harsh.png";
import Lakshmeesh from "../assets/Team/Lakshmeesh.png";
import Nitin from "../assets/Team/Nitin.png";
import Kanishk from "../assets/Team/Kanishk@1x.png";
import Devansh from "../assets/Team/Devansh.png";
import Rajeev from "../assets/Team/Rajeev@1x.png";
import HarshLohia from "../assets/Team/HarshLohia.png";
import Akshat from "../assets/Team/Akshat.png";
import Aikansh from "../assets/Team/Aikansh.png";
import Donna from "../assets/Team/Donna.png";
import Harshith from "../assets/Team/Harshith.png";
import Chitragna from "../assets/Team/Chitragna.png";
import Aman from "../assets/Team/Aman@1x.png";
import Kulsum from "../assets/Team/Kulsum@1x.png";
import Kundana from "../assets/Team/Kundana@1x.png";
import Faiz from "../assets/Team/Faiz@1x.png";
import Raul from "../assets/Team/Raul@1x.png";

export const foundingTeam = [
  {
    name: "Raghu Kumar",
    title: "Co-founder",
    linkedIn: "https://www.linkedin.com/in/raghukumar1985/",
    img: Raghu,
  },
  {
    name: "Harsh Agarwal",
    title: "Co-founder",
    linkedIn: " https://www.linkedin.com/in/harsh-agarwal-461aa549/",
    img: Harsh,
  },
  {
    name: "Kanishk Agarwal",
    title: "Co-founder",
    linkedIn:
      "https://www.linkedin.com/in/kanishkagarwal/?originalSubdomain=in",
    img: Kanishk,
  },
];

export const coreTeam = [
  {
    name: "Lakshmeesh Rao",
    title: "Head of Product",
    linkedIn: "https://www.linkedin.com/in/lakshmeesh-rao-724b5334/",
    img: Lakshmeesh,
  },
  {
    name: "Nitin Singh",
    title: "Head of Technology",
    linkedIn:
      "https://www.linkedin.com/in/nitin-singh-b01967114/?originalSubdomain=in",
    img: Nitin,
  },
  {
    name: "Kulsum Mohammed",
    title: "VP Content and Communication",
    linkedIn:
      "https://www.linkedin.com/in/kulsum-m-b54b56a4/?originalSubdomain=in",
    img: Kulsum,
  },

  {
    name: "Raul Dantas",
    title: "VP Community",
    linkedIn: "https://www.linkedin.com/in/raul-dantas-101485143/",
    img: Raul,
  },

  // {
  //   name: "Devansh Pawan",
  //   title: "VP Design & Communications",
  //   linkedIn: "https://www.linkedin.com/in/devanshpawan/",
  //   img: Devansh,
  // },
  {
    name: "Rajeev Pogaru",
    title: "Project Manager - Design",
    linkedIn:
      "https://www.linkedin.com/in/rajeev-bharadwaj-pogaru-18431557/?originalSubdomain=in",
    img: Rajeev,
  },
  {
    name: "Akshat Agarwal",
    title: "Frontend Technologies",
    linkedIn: "https://www.linkedin.com/in/aksagarwal42/",
    img: Akshat,
  },
  {
    name: "Harsh Lohia",
    title: "Frontend Technologies",
    linkedIn: "https://www.linkedin.com/in/harsh-lohia-637a7b19b/",
    img: HarshLohia,
  },
  {
    name: "Aikansh Boyal",
    title: "Frontend Technologies",
    linkedIn: "https://www.linkedin.com/in/aikansh-boyal-01811b200/",
    img: Aikansh,
  },
  {
    name: "Aman Sharma",
    title: "Full-Stack Dev",
    linkedIn: "https://www.linkedin.com/in/aman-sharmaweb/",
    img: Aman,
  },
  {
    name: "Kundana Sreejani",
    title: "Quality Assurance Engineer",
    linkedIn: "https://www.linkedin.com/in/kundanasreejani/",
    img: Kundana,
  },

  {
    name: "Faiz Khalid",
    title: "Marketing Associate",
    linkedIn: "https://www.linkedin.com/in/faiz-khalid-301a63a3/",
    img: Faiz,
  },
  {
    name: "Chitrangna Sharma",
    title: "Customer Experience Manager",
    linkedIn: "https://www.linkedin.com/in/chitrangna-sharma-086b14b3/",
    img: Chitragna,
  },
];
